import * as Sentry from '@sentry/react';
import Container, { Service } from 'typedi';

import { DocumentService } from './document.service';
import { ModalService } from './modal.service';
import { HistoryService } from './history.service';
import { copyFields } from 'utils/object-parse';
import { ContextService } from './context.service';
import { LocalStorageService } from './local-storage.service';
import { RESERVATION_ACCESS_KEY, VOUCHER_ACCESS_KEY } from 'constants/local-storage-key';

const documentService = Container.get(DocumentService);
const modalService = Container.get(ModalService);
const contextService = Container.get(ContextService);
const localStorageService = Container.get(LocalStorageService);


const VERSION = '/v1';
const API_ORIGIN = process.env.API_ORIGIN || '';
const API_PREFIX = `${API_ORIGIN}/api-${contextService.apiDomain}${VERSION}`;
const BLOCKS_REGEX = /^.*\/.*?blocks\/([^\/]*)/;

export const PREFIX = {
    BASE: API_PREFIX,
    BLOCKS: `${API_PREFIX}/blocks`,
    MULTI_BLOCKS: `${API_PREFIX}/multi-blocks`,
    HOTELS_CURRENT: `${API_PREFIX}/hotels/current/`,
    HOTELS: `${API_PREFIX}/hotels/`,
    USERS: `${API_PREFIX}/users/`,
    USERS_ME: `${API_PREFIX}/users/me`,
    OTP: `${API_PREFIX}/otp-`,
    RESERVATIONS: `${API_PREFIX}/reservations`,
    TEAMS: `${API_PREFIX}/teams`,
    PAYMENT: `${API_PREFIX}/payment`,
    ORGANIZATION: `${API_PREFIX}/organization`,
    PMS_HOTELS: `${API_PREFIX}/pms/hotels/`,
    GENERAL: `${API_PREFIX}/general`,
    UPLOAD: `${API_PREFIX}/upload`,
    SEARCH: `${API_PREFIX}/search-all`,
    TRANSACTIONS: `${API_PREFIX}/transaction-groups`,
    SUB_BLOCKS: `${API_PREFIX}/subblocks/`,
    PKGS: `${API_PREFIX}/packages`,
    PKG_CATEGORIES: `${API_PREFIX}/package-categories`,
    PKG_TAGS: `${API_PREFIX}/item-tags`,
    OUTLET: `${API_PREFIX}/vendors`,
    BUSINESS_TYPES: `${API_PREFIX}/business-types`,
    URL_TARGET_TYPE: `${API_PREFIX}/url-target-type`,
}

export const GUEST_PREFIX = {
    BOOKING: `${API_PREFIX}/booking/`,
    SEARCH: '/search',
    UPSELL: '/upsell',
    SELECT_ROOM: '/select-room',
    UPGRADE_ROOM: '/upgrade-room',
    RESERVATION: `${API_PREFIX}/reservations/`,
    MEMBERSHIP: 'membership',
    RATEPLANS: 'rate-plans/',
    RATES: '/rates'
}

export const VOUCHER_PREFIX = {
    PACKAGES: `${API_PREFIX}/packages`,
    ORDERS: `${API_PREFIX}/orders`,
    OUTLETS: `${API_PREFIX}/vendors`,
    VOUCHERS: `${API_PREFIX}/vouchers`,
    PROMOS: `${API_PREFIX}/promos`,
    COURIERS: `${API_PREFIX}/couriers`,
    DELIVERY_RATES: `${API_PREFIX}/delivery-rates`,
    RECIPIENTS: `${API_PREFIX}/recipients`
}

export enum RES_STATUS {
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export interface ICommonResponse {
    code: string;
    message: string;
    status: string;
    errors: any;
}

export interface IErrorJSON {
    field: string,
    message: string,
}

export interface IDataTableResponse<T, TQuery> extends ICommonResponse {
    data: {
        results: T[];
        filter: TQuery;
        count: number;
        message?: string;
        featured_limit?: number;
        featured_count?: number;
        refundable_amount?: number;
    }
}

const enum METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

type THandleResponseOption = {
    showSuccessMgs?: boolean;
    ignoreFailureMsg?: boolean;
    customErrorMsg?: string;
    rejectErrors?: boolean;
}

export interface IResponseData<T> extends ICommonResponse {
    data: T;
}

@Service()
export class APIService {
    get = async (path: string, customHeader?: HeadersInit): Promise<Response> => {
        const options = this.buildOption();
        const headers = { ...this.buildHeaders(path), ...customHeader };
        const res = await fetch(path, { ...options, headers });

        this.handleErrorCode(res)
        return res;
    }

    post = async (path: string, body?: BodyInit): Promise<Response> => {
        const options = this.buildOption(METHOD.POST);
        const headers = this.buildHeaders(path, body);
        const res = await fetch(path, { ...options, body, headers });

        this.handleErrorCode(res)
        return res;
    }

    put = async (path: string, body?: BodyInit): Promise<Response> => {
        const options = this.buildOption(METHOD.PUT);
        const headers = this.buildHeaders(path, body);
        const res = await fetch(path, { ...options, body, headers });

        this.handleErrorCode(res)
        return res;
    }

    delete = async (path: string): Promise<Response> => {
        const options = this.buildOption(METHOD.DELETE);
        const headers = this.buildHeaders(path);
        const res = await fetch(path, { ...options, headers });

        this.handleErrorCode(res)
        return res;
    }

    private buildHeaders(path: string, body?: BodyInit): HeadersInit {

        const historyService = Container.get(HistoryService);

        const headers: HeadersInit = {
            'X-CSRF-Token': documentService.getCSRFToken(),
            'Authorization': `${localStorageService.get('tokenType')} ${localStorageService.get('accessToken')}`
        }

        if (typeof body === 'string') {
            copyFields(headers, { 'Content-Type': 'application/json' })
        }

        const hotelRefererId = historyService.basename.split('/').pop();
        const isDefaultPath = path.match(/\/api\/v1\/hotels\/[a-z0-9]{24}\/default/);
        if (contextService.isHotel && (!isDefaultPath && hotelRefererId)) {
            copyFields(headers, {
                'Idem-Referer-Type': 'Hotel',
                'Idem-Referer-Id': hotelRefererId
            })
        }

        if (contextService.isPlanner) {
            const execArray = BLOCKS_REGEX.exec(path);

            if (execArray) {
                copyFields(headers, {
                    'Idem-Referer-Type': 'RoomBlock',
                    'Idem-Referer-Id': execArray[1]
                })
            }
        }

        if (contextService.isGuest) {
            const keyName = historyService.routeType === 'reservation' ? RESERVATION_ACCESS_KEY : VOUCHER_ACCESS_KEY;

            copyFields(headers, {
                'Idem-Guest-Access': localStorageService.get(keyName) || ''
            })
        }

        return headers;
    }

    private buildOption(method: METHOD = METHOD.GET): RequestInit {
        return {
            method,
            credentials: 'same-origin'
        }
    }

    handleErrorCode = async (res: Response): Promise<void> => {

        const historyService = Container.get(HistoryService);

        if (
            (res.status === 401 && historyService.routeType !== 'auth')
            || (res.url.includes(PREFIX.USERS_ME) && res.status === 500)
        ) {
            const loginPath = contextService.isGuest ? '/login' : `${historyService.domainBasename}/login`;
            historyService.navigate(loginPath);
            historyService.setRouteType(false, loginPath);
        }
    }

    handleErrorBoolean = async (res: Response, option: THandleResponseOption = {}): Promise<boolean> => {
        let msg = '', isDone = false, json: any = {};

        try {
            json = await res.json();
            if (json.status === RES_STATUS.SUCCESS) {
                option.showSuccessMgs && (msg = json.message || 'success');
                isDone = true;
            } else throw new Error()
        } catch (e) {
            if (!option.ignoreFailureMsg) {
                msg = option.customErrorMsg || (json.message ? json.message : json.errors?.[0]?.message);
                !msg && (msg = 'Fail, something went wrong!');
            }
            Sentry.captureException(e);
        }

        msg && modalService.showNotification(msg);
        return isDone;
    }

    parseResponseData = async <T extends any>(res: Response, handler: (x: any) => T, defaultRes: T, option: THandleResponseOption = {}, customErrMsg?: string): Promise<T> => {
        let msg = '', returnData = defaultRes, json: any = {};

        try {
            json = await res.json();
            if (json.status === RES_STATUS.SUCCESS) {
                option.showSuccessMgs && (msg = json.message || 'success');
                returnData = handler(json);
            } else throw new Error()
        } catch (e) {
            console.log('log 555', option);
            
            if (option.rejectErrors) return Promise.reject(json.errors || [{ message: json.message }])

            if (!option.ignoreFailureMsg) {
                msg = option.customErrorMsg || (json.message ? json.message : json.errors?.[0]?.message);
                !msg && (msg = 'Fail, something went wrong!');
            }
            Sentry.captureException(e);
            if (customErrMsg) {
                msg = customErrMsg;
            }
        }

        msg && modalService.showNotification(msg);
        return returnData;
    }
}
